<template>
  <div>
    <breadcrumbs
      :page-title="pageTitle"
      :page-subtitle="pageSubtitle"
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else>
        <div class="custom-search d-flex flex-wrap justify-content-between align-items-center mb-1">
          <div class="total">
            <strong>{{ $t('sites.sitetotal') }}: {{ totalRecords }}</strong>
          </div>
          <div class="table-filters">
            <div class="d-flex align-items-center">
              <b-input-group
                class="input-group-merge pnx-search"
              >
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="cursor-pointer"
                    @click="changeUrl('searchTitle', serverParams.searchTitle)"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="serverParams.searchTitle"
                  :value="serverParams.searchTitle"
                  :placeholder="$t('form.search')"
                  type="text"
                  class="d-inline-block"
                  @keyup.enter="changeUrl('searchTitle', $event.target.value)"
                />
                <b-input-group-append is-text>
                  <div class="search-input-clear">
                    <feather-icon
                      v-if="serverParams.searchTitle!==''"
                      v-b-tooltip.hover.top="$t('form.reset')"
                      class="cursor-pointer"
                      size="18"
                      icon="XIcon"
                      @click="clearSearch"
                    />
                  </div>
                  <b-dropdown
                    ref="dropdownFilter"
                    :variant="dropdownFilterVariant"
                    toggle-class="text-decoration-none"
                    right
                    no-caret
                    offset="15"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        size="18"
                        icon="SlidersIcon"
                      />
                    </template>
                    <b-dropdown-form
                      :style="{minWidth: '292px'}"
                    >
                      <h5>
                        {{ $t('campaigns.filters') }}
                      </h5>
                      <hr>
                      <div class="d-flex align-items-center mb-1">
                        <label
                          class="mr-1"
                          :style="{minWidth: '60px'}"
                        >
                          {{ $t('campaigns.status') }}
                        </label>
                        <b-form-select
                          v-model="serverParams.filterName"
                          :options="[{ value: '', text: this.$t('users.all') }, ...optionsStatuses]"
                          @change="changeUrl('filterName', $event)"
                        />
                      </div>
                      <div class="d-flex align-items-center  mb-1">
                        <label
                          class="mr-1"
                          :style="{minWidth: '60px'}"
                        >
                          {{ $t('sites.category') }}
                        </label>
                        <b-form-select
                          v-model="serverParams.category"
                          :options="[{ value: '', text: this.$t('users.all') }, ...categories]"
                          @change="changeUrl('category', $event)"
                        />
                      </div>
                      <div class="d-flex align-items-center  mb-1">
                        <label
                          class="mr-1"
                          :style="{minWidth: '60px'}"
                        >
                          {{ $t('stats.country') }}
                        </label>
                        <b-form-select
                          v-model="serverParams.country"
                          :options="[{ value: '', text: this.$t('users.all') }, ...countries]"
                          @change="changeUrl('country', $event)"
                        />
                      </div>
                      <b-button
                        v-if="serverParams.country!=='' || serverParams.category!=='' || serverParams.filterName!==''"
                        block
                        variant="danger"
                        @click="clearFilter"
                      >
                        <span>{{ $t('form.reset') }}</span>
                      </b-button>
                      <b-button
                        block
                        variant="outline-primary"
                        @click="$refs.dropdownFilter.hide(true)"
                      >
                        <span>{{ $t('Close') }}</span>
                      </b-button>
                    </b-dropdown-form>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>

        <div
          v-if="rows.length"
          class="sites-table"
        >
          <!-- table -->
          <vue-good-table
            v-if="totalRecords > 0"
            ref="sitesTable"
            mode="remote"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :pagination-options="{enabled: true}"
            :sort-options="{
              enabled: true,
              initialSortBy: { field: serverParams.sortField, type: serverParams.sortOrder }
            }"
            :total-rows="totalRecords"
            style-class="vgt-table striped"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
          >
            <template slot="column-filter">
              <span style="display:none;" />
            </template>
            <!-- Slot: Table Column -->
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span
                v-if="props.column.label ==='Status'"
                class="text-nowrap"
              >
                {{ $t('campaigns.status') }}
              </span>
              <span
                v-else-if="props.column.label ==='Name'"
                class="text-nowrap"
              >
                {{ $t('sites.sitetitle') }}
              </span>
              <span
                v-else-if="props.column.label ==='Category'"
                class="text-nowrap"
              >
                {{ $t('sites.category') }}
              </span>
              <span
                v-else-if="props.column.label ==='Country'"
                class="text-nowrap"
              >
                {{ $t('stats.country') }}
              </span>
              <span
                v-else-if="props.column.label ==='Impressions'"
                class="text-nowrap"
              >
                {{ $t('stats.impressions') }}
              </span>
              <span
                v-else-if="props.column.label ==='Action'"
                class="text-nowrap"
              >
                {{ $t('Action') }}
              </span>
              <span v-else>
                {{ props.column.label }}
              </span>
            </template>

            <!-- Slot: Table Row -->
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Status -->
              <span
                v-if="props.column.field === 'status'"
                class="text-nowrap"
              >
                <b-form-checkbox
                  :checked="props.row.status === 'active' ? true : false"
                  name="check-button"
                  switch
                  size="lg"
                  @change="changeStatus(props.row._id)"
                />
              </span>

              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'title'"
                class="text-nowrap"
              >
                <router-link :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/'">
                  <strong>{{ trimEllip(props.row.title, 40) }}</strong>
                </router-link>
                <b-badge
                  v-if="props.row.is_moderate"
                  class="ml-25"
                  :variant="statusVariant('Resigned')"
                >
                  {{ $t('campaigns.moderation') }}
                </b-badge>
                <template v-if="authUserData.role.includes('admin') && props.row.tags">
                  <b-badge
                    v-for="(tag, idx) in props.row.tags.split(',')"
                    :key="idx"
                    class="tag-badge ml-25"
                    :variant="statusVariant('Tags')"
                    @click="setTag(tag)"
                  >
                    {{ tag.trim() }}
                  </b-badge>
                </template>
                <br>
                <small>{{ props.row.domain }}</small>
              </span>

              <!-- Column: Category -->
              <span
                v-if="props.column.field === 'category'"
                class="text-nowrap"
              >
                {{ toCapitalize(props.row.category) }}
              </span>

              <!-- Column: Country -->
              <span
                v-if="props.column.field === 'country'"
                class="text-nowrap"
              >
                {{ toCapitalize(props.row.country) }}
              </span>

              <!-- Column: Country -->
              <span
                v-if="props.column.field === 'shows'"
                class="text-nowrap"
              >
                {{ props.row.shows }}
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span class="d-flex justify-content-center">
                  <b-button
                    v-b-tooltip.hover.top="$t('Statistics')"
                    :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id"
                    variant="flat"
                    class="btn-action"
                  >
                    <feather-icon
                      icon="BarChart2Icon"
                      size="16"
                    />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover.top="$t('form.change')"
                    :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/edit/'"
                    variant="flat"
                    class="btn-action"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="16"
                    />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover.top="$t('page.features.widgets')"
                    :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/widgets/'"
                    variant="flat"
                    class="btn-action"
                  >
                    <feather-icon
                      icon="GridIcon"
                      size="16"
                    />
                  </b-button>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    right
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/promotions/'"
                    >
                      <feather-icon
                        icon="TagIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('Promotions') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/clicks/'"
                    >
                      <feather-icon
                        icon="MousePointerIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('Clicks sources') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/toparticles/'"
                    >
                      <feather-icon
                        icon="ActivityIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('TOP articles') }}</span>
                    </b-dropdown-item>
                    <!-- Delete //-->
                    <b-dropdown-divider />
                    <b-dropdown-item
                      variant="danger"
                      @click="deleteEntity('sites', props.row._id, props.row.title)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('form.delete') }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    {{ $t('message.pagelength') }}
                  </span>
                  <b-form-select
                    v-model="serverParams.size"
                    :options="['5','10','20','40']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
                </div>
                <div>
                  <b-pagination
                    :value="serverParams.page"
                    :total-rows="props.total"
                    :per-page="serverParams.size"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
        <div v-else-if="rows.length === 0 && !loading">
          <div class="text-center mt-5 mb-5">
            <h5>{{ $t('sites.nositessearch') }}</h5>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { trimEllip, toCapitalize } from '@/utils/helpers'
import { getUserData } from '@/auth/utils'
import currencyFormatter from '@/utils/currency-formatter'
import options from '@/libs/options'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import {
  BCard,
  BSpinner,
  BPagination,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormCheckbox,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BDropdownForm,
  BDropdownDivider,
  BButton,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Breadcrumbs,
    BSpinner,
    BCard,
    VueGoodTable,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BDropdownDivider,
    BButton,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      currency: currencyFormatter,
      account: [],
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      loading: true,
      isLoading: false,
      totalRecords: 0,
      dir: false,
      trimEllip,
      toCapitalize,
      columns: [
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
          width: '9%',
          type: 'boolen',
        },
        {
          label: 'Domain',
          field: 'domain',
          hidden: true,
        },
        {
          label: '_id',
          field: '_id',
          hidden: true,
        },
        {
          label: 'tags',
          field: 'tags',
          hidden: true,
        },
        {
          label: 'Category',
          field: 'category',
        },
        {
          label: 'Country',
          field: 'country',
          globalSearchDisabled: true,
        },
        {
          label: 'Impressions',
          thClass: 'text-center',
          tdClass: 'text-center',
          field: 'shows',
          type: 'number',
          width: '10%',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '10%',
          sortable: false,
        },
      ],
      rows: [],
      serverParams: {
        sortField: 'status',
        filterName: '',
        category: '',
        country: '',
        sortOrder: 'asc',
        page: 1,
        size: 20,
        searchTitle: '',
      },
      optionsStatuses: [
        { value: 'is_active', text: 'Active' },
        { value: 'is_moderate', text: 'Moderation' },
        { value: 'is_new', text: 'New' },
        { value: 'parser_issue', text: 'Parser' },
        { value: 'has_shows', text: 'Have impressions' },
      ],
      ...options,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        active       : 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        Tags         : 'light-secondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    dropdownFilterVariant() {
      if (this.serverParams.country !== '' || this.serverParams.category !== '' || this.serverParams.filterName !== '') {
        return 'link'
      }
      return 'link-secondary'
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    'serverParams.sortOrder': {
      handler() {
        this.fetchData()
      },
      deep: false,
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    setTag(tag) {
      // this.serverParams.searchTitle = tag.trim()
      this.changeUrl('tag', tag.trim())
    },
    fetchData() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      if (urlParams.filterName) this.serverParams.filterName = urlParams.filterName
      if (urlParams.category) this.serverParams.category = urlParams.category
      if (urlParams.country) this.serverParams.country = urlParams.country
      if (urlParams.sortField) this.serverParams.sortField = urlParams.sortField
      if (urlParams.sortOrder) this.serverParams.sortOrder = urlParams.sortOrder
      if (urlParams.page) this.serverParams.page = urlParams.page
      if (urlParams.size) this.serverParams.size = urlParams.size
      if (urlParams.searchTitle) this.serverParams.searchTitle = urlParams.searchTitle

      if (this.$route.params.userId === undefined) {
        this.fetchSites(this.filterServerParams())
      } else {
        this.fetchAccountSites(this.$route.params.userId)
      }
    },
    changeUrl(type, value) {
      this.serverParams[type] = value
      this.$router.replace({
        name: 'dashboard-sites',
        query: this.filterServerParams(),
      }).catch(() => {})
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || []
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.setBreabcrumbs()
    },
    async fetchSites(params) {
      this.loading = true
      const responseData = await useJwt.sites(params)
      this.rows = responseData.data.sites || []
      if (this.rows.length) {
        this.totalRecords = responseData.data.total
      }
      this.pageTitle = this.$t('Sites')
      this.loading = false
      this.setBreabcrumbs()
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage
      this.$router.replace({
        name: 'dashboard-sites',
        query: this.filterServerParams(),
      }).catch(() => {})
    },
    onSortChange(params) {
      this.serverParams.sortField = params[0].field
      this.serverParams.sortOrder = params[0].type
      const serverParams = this.filterServerParams()
      const url = new URL(window.location)
      Object.keys(serverParams).forEach(i => {
        url.searchParams.set(i, serverParams[i])
      })
      window.history.pushState({}, '', url)
    },
    onPerPageChange(params) {
      this.serverParams.size = params.currentPerPage
      this.serverParams.page = 1
      this.$router.replace({
        name: 'dashboard-sites',
        query: this.filterServerParams(),
      }).catch(() => {})
    },
    async fetchAccountSites(userId) {
      this.rows = []
      const responseAccountData = await useJwt.accountSites(userId)
      this.rows = responseAccountData.data.sites || []
      this.loading = false
      this.getUserData()
    },
    filterServerParams() {
      const filtredParams = {}
      Object.keys(this.serverParams).forEach(p => {
        if (this.serverParams[p] !== '') {
          filtredParams[p] = this.serverParams[p]
        }
      })
      return filtredParams
    },
    checkEnter(event) {
      if (event.code !== 'Enter') {
        event.preventDefault()
      }
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'sites',
          to: '/sites',
        },
      ]
    },
    async changeStatus(id) {
      const responseData = await useJwt.getSite(id)
      const site = responseData.data.site || []
      site.status = site.status === 'inactive' ? 'active' : 'inactive'
      if (site.status === 'active') {
        this.$swal({
          icon: 'success',
          title: 'Activation!',
          text: 'Successfully activated.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        useJwt.setSite(id, { site })
      } else {
        this.$swal({
          title: 'Confirmation',
          text: 'Will be inactive',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deactivation!',
              text: 'Successfully deactivated',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            site.status = 'active'
            this.$swal({
              title: 'Cancelled',
              text: 'Status remain active',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          useJwt.setSite(id, { site })
        })
      }
    },
    clearSearch() {
      this.changeUrl('searchTitle', '')
    },
    clearFilter() {
      this.serverParams.filterName = ''
      this.serverParams.category = ''
      this.serverParams.country = ''
      this.serverParams.sortField = 'title'
      this.serverParams.sortOrder = 'asc'
      this.changeUrl('filterName', '')
    },
    deleteEntity(entity, id, title) {
      this.$swal({
        title: `${this.$t('swal.deletetitle')} ${title}?`,
        text: this.$t('swal.deletemessage'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('form.cancel'),
        confirmButtonText: this.$t('form.delete'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteEntity(entity, id).then(deleted => {
            if (deleted.status === 204) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('swal.successfullydeleted'),
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.fetchData()
            }
          })
        }
      })
    },
  },
}
</script>
